<template>
  <div class="home">
    <div class="head">
      <img class="user-head-img" :src="userHeadImg" alt="">
      <div class="user-info">
        <span class="user-name">{{userName}}</span>
        <span class="user-code">{{userCode}}</span>
        <!-- <span class="exit-btn"><img src="@/assets/image/home-tuichu.png" alt=""> 退出</span> -->
      </div>
      <div class="bg" :style="bgImg"></div>
    </div>
    <div class="container">
      <div class="fn-box">
        <div class="fn-box-title">
          我的车牌
        </div>
        <div class="fn-item" @click="toNext('/cars')">
          <img src="@/assets/image/home-chepai.png" alt="" class="left-icon">
          <div class="item-cont">
            <span class="title van-ellipsis my-cars">{{ cars }}</span>
          </div>
          <img src="@/assets/image/home-right.png" alt="" class="right-icon">
        </div>
      </div>
      <div class="fn-box">
        <div class="fn-box-title">
          停车服务
        </div>
        <div class="fn-item" @click="toNext('/inquirepay')">
          <img src="@/assets/image/home-jiaofei.png" alt="" class="left-icon">
          <div class="item-cont">
            <span class="title">停车缴费</span>
            <span class="des">场内缴费，体验快捷出场。</span>
          </div>
          <img src="@/assets/image/home-right.png" alt="" class="right-icon">
        </div>
        <!-- <div class="fn-item" @click="toNext('/monthly')">
          <img src="@/assets/image/home-yuezu.png" alt="" class="left-icon">
          <div class="item-cont">
            <span class="title">月租服务</span>
            <span class="des">在线办理月租简单方便。</span>
          </div>
          <img src="@/assets/image/home-right.png" alt="" class="right-icon">
        </div> -->
        <div class="fn-item" @click="toNext('/orders')">
          <img src="@/assets/image/home-zhangdan.png" alt="" class="left-icon">
          <div class="item-cont">
            <span class="title">我的账单</span>
            <span class="des">在这里查看消费记录。</span>
          </div>
          <img src="@/assets/image/home-right.png" alt="" class="right-icon">
        </div>
        <!-- <div class="fn-item" @click="toNext('/invoice')">
          <img src="@/assets/image/home-tingche.png" alt="" class="left-icon">
          <div class="item-cont">
            <span class="title">发票开具</span>
            <span class="des">在这里查看和开具电子发票。</span>
          </div>
          <img src="@/assets/image/home-right.png" alt="" class="right-icon">
        </div>                       -->
      </div>
    </div>
  </div>
</template>

<script>
import { apiCarlist } from "@/utils/api"

export default {
  name: 'Home',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      userInfo: null,
      userHeadImg: '',
      bgImg: {
        background: '',
        'background-position': 'center',
        'background-size': 'cover'
      },
      userName: 'Yodoes',
      userCode: 'VIP001',
      cars: '',
      carlist: [],

    }
  },
  computed: {},
  watch: {},
  created () {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.userHeadImg = this.userInfo.headimgurl
    this.bgImg.background = 'url(' + this.userInfo.headimgurl + ')'
    this.userName = this.userInfo.nickname
    this.userCode = this.userInfo.userCode

    this.getCarList()
  },
  mounted () {},
  destroyed () {},
  methods: {
    getCarList (){
      let that = this
      let param = {
        registrationId: localStorage.getItem('registrationId'),
        currentPage: 1,
        pageSize: 20
      }
      apiCarlist(param)
        .then(result => {
          if (result.status == "200") {
            that.carlist = result.data
            if(that.carlist.length == 0){
              that.cars = '您还未绑定车辆，点击绑定'
            }else{
              let carlistArr = result.data.map(item => item.carLicense)
              that.cars = carlistArr.join(',')
            }
          } else {
            that.cars = '获取车牌失败，可点击查看'
          }
        })
        .catch(err => {
          window.console.log(err);
        })
    }, 
    toNext (path) {
      if(path === '/cars' && this.carlist.length == 0){
        this.$router.push('/addcar')
      }else{
        this.$router.push(path)
      }
      
    }
  },
}
</script>

<style scoped lang="scss">
.home{
  .head{
    height: 300px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    position: relative;
    .bg{
      // background: url('../../assets/image/avatar.jpg');
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      filter: blur(15px);
      background-position: center;
      background-size: cover;
    }
    .user-head-img{
      width: 132px;
      height: 132px;
      border-radius: 50%;
      margin-left: 53px;
      margin-right: 40px;
    }
    .user-info{
      display: flex;
      flex-direction: column;
      align-items: baseline;
    }
    .user-name{
      font-size: 44px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 20px;
    }
    .user-code{
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      margin-bottom: 12px;
    }
    .exit-btn{
      height: 34px;
      line-height: 34px;
      width: 117px;
      border: 1px solid #FFFFFF;
      border-radius: 17px;
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      img{
        width: 22px;
        height: 22px;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        bottom: 1px;
      }
    }
  }
  .container{
    background: #FFFFFF;
    // box-shadow: 1px 4px 20px 0px rgba(174, 186, 190, 0.7);
    border-radius: 33px 33px 0px 0px;
    position: relative;
    top: -40px;
    text-align: left;
    padding: 25px;
    .fn-box{
      margin-bottom: 47px;
      .fn-box-title{
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #343434;
        margin-left: 17px;
      }
      .fn-item{
        display: flex;
        align-items: center;
        height: 120px;
        border-bottom: 1px solid rgba(145, 145, 145, .3);
        box-sizing: border-box;
        position: relative;
        padding: {
          top: 47px;
          left: 17px;
          right: 17px;
          bottom: 17px;
        };
        .left-icon{
          width: 49px;
          height: 49px;
          margin-right: 23px;
        }
        .right-icon{
          width: 12px;
          height: 23px;
          position: absolute;
          right: 17px;
        }
        .item-cont{
          display: flex;
          flex-direction: column;
          .my-cars{
            width: 500px;
          }
          .title{
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #424242;
          }
          .des{
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #9A9A9A;
          }
        }
      }
    }
  }
}
</style>