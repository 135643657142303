import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/home'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: '个人中心', requiresAuth: true, isScan: false, client: 'user' }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "user" */ '../pages/login'),
    meta: { title: '用户登录', requiresAuth: false, isScan: false, client: 'user'}
  },
  {
    path: '/bindphone',
    name: 'BindPhone',
    component: () => import(/* webpackChunkName: "user" */ '../pages/bindphone'),
    meta: { title: '绑定手机号', requiresAuth: false, isScan: false, client: 'user' }
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: () => import(/* webpackChunkName: "user" */ '../pages/agreement'),
    meta: { title: '服务协议', requiresAuth: false, isScan: false, client: 'user' }
  },
  {
    path: '/inquirepay',
    name: 'InquirePay',
    component: () => import(/* webpackChunkName: "user" */ '../pages/inquirepay'),
    meta: { title: '停车缴费', requiresAuth: true, isScan: false, client: 'user' }
  },
  {
    path: '/paymentinfo',
    name: 'PaymentInfo',
    component: () => import(/* webpackChunkName: "user" */ '../pages/inquirepay/paymentinfo'),
    meta: { title: '停车缴费信息', requiresAuth: true, isScan: false, client: 'user' }
  },
  {
    path: '/cars',
    name: 'Cars',
    component: () => import(/* webpackChunkName: "user" */ '../pages/cars'),
    meta: { title: '我的车辆', requiresAuth: true, isScan: false, client: 'user' }
  },
  {
    path: '/addcar',
    name: 'AddCar',
    component: () => import(/* webpackChunkName: "user" */ '../pages/cars/addCar'),
    meta: { title: '添加车辆', requiresAuth: true, isScan: false, client: 'user' }
  },
  {
    path: '/monthly',
    name: 'Monthly',
    component: () => import(/* webpackChunkName: "user" */ '../pages/monthly'),
    meta: { title: '月租服务', requiresAuth: true, isScan: false, client: 'user' }
  },
  {
    path: '/orders2',
    name: 'Orders',
    component: () => import(/* webpackChunkName: "user" */ '../pages/orders'),
    meta: { title: '我的账单', requiresAuth: true, isScan: false, client: 'user' }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import(/* webpackChunkName: "user" */ '../pages/orders/order2.vue'),
    meta: { title: '我的账单', requiresAuth: true, isScan: false, client: 'user' }
  },
  {
    path: '/details',
    name: 'Details',
    component: () => import(/* webpackChunkName: "user" */ '../pages/orders/details'),
    meta: { title: '账单详情', requiresAuth: true, isScan: false, client: 'user' }
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import(/* webpackChunkName: "user" */ '../pages/orders/feedback'),
    meta: { title: '订单疑问', requiresAuth: true, isScan: false, client: 'user' }
  },
  {
    path: '/invoice',
    name: 'Invoice',
    component: () => import('../pages/invoice'),
    meta: { title: '发票开具', requiresAuth: true, isScan: false, client: 'user' }
  },
  {
    path: '/paysuccess',
    name: 'PaySuccess',
    component: () => import('../pages/result/paysuccess'),
    meta: { title: '支付成功', requiresAuth: false, isScan: false, client: 'user' }
  },
  {
    path: '/inside-fixedcode',
    name: 'InsideFixedcode',
    component: () => import(/* webpackChunkName: "scan" */ '../pages/scan/inside-fixedcode'),
    meta: { title: '场内订单查询', requiresAuth: true, isScan: true, client: 'user' }
  },
  {
    path: '/outside-fixedcode',
    name: 'OutsideFixedcode',
    component: () => import(/* webpackChunkName: "scan" */ '../pages/scan/outside-fixedcode'),
    meta: { title: '出口订单查询', requiresAuth: true, isScan: true, client: 'user' }
  },
  {
    path: '/payorder',
    name: 'PayOrder',
    component: () => import(/* webpackChunkName: "scan" */ '../pages/scan/payorder'),
    meta: { title: '订单详情', requiresAuth: true, isScan: true, client: 'user' }
  },
  {
    path: '/payresult',
    name: 'PayResult',
    component: () => import(/* webpackChunkName: "scan" */ '../pages/scan/payresult'),
    meta: { title: '支付结果', requiresAuth: false, isScan: true, client: 'user' }
  },
  {
    path: '/unlicensed-car-in',
    name: 'UnlicensedCarIn',
    component: () => import(/* webpackChunkName: "scan" */ '../pages/scan/unlicensed-car-in'),
    meta: { title: '无牌车入场', requiresAuth: true, isScan: true, client: 'user' }
  },
  {
    path: '/unlicensed-car-out',
    name: 'UnlicensedCarOut',
    component: () => import(/* webpackChunkName: "scan" */ '../pages/scan/unlicensed-car-out'),
    meta: { title: '无牌车出场', requiresAuth: true, isScan: true, client: 'user' }
  },
  {
    path: '/mrpay',
    name: 'Mrpay',
    component: () => import('../pages/paypage/mrpay'),
    meta: { title: '支付', requiresAuth: false, isScan: true, client: 'user' }
  },
  {
    path: '/mrpaysuc',
    name: 'Mrpaysuc',
    component: () => import('../pages/paypage/paysuccess'),
    meta: { title: '支付结果', requiresAuth: false, isScan: true, client: 'user' }
  },
  {
    path: '/coupon-scan',
    name: 'CouponScan',
    component: () => import(/* webpackChunkName: "scan" */ '../pages/scan/coupon-scan'),
    meta: { title: '扫码领优惠', requiresAuth: false, isScan: false, client: 'user' }
  },
  {
    path: '/coupon-receive',
    name: 'CouponReceive',
    component: () => import(/* webpackChunkName: "scan" */ '../pages/scan/coupon-receive'),
    meta: { title: '优惠券领取', requiresAuth: false, isScan: false, client: 'user' }
  },
  {
    path: '/coupon-success',
    name: 'CouponSuccess',
    component: () => import(/* webpackChunkName: "scan" */ '../pages/scan/coupon-success'),
    meta: { title: '领取成功', requiresAuth: false, isScan: false, client: 'user' }
  },
  {
    path: '/stateless',
    name: 'Stateless',
    component: () => import(/* webpackChunkName: "blacklist" */ '../pages/blacklist/stateless'),
    meta: {title: '黑名单', requiresAuth: false, isScan: false, client: 'user'}
  }, 
  {
    path: '/paySuc',
    name: 'PaySuc',
    component: () => import(/* webpackChunkName: "blacklist" */ '../pages/blacklist/paySuc'),
    meta: {title: '支付成功', requiresAuth: false, isScan: false, client: 'user'}
  },
  {
    path: '/m/pre/searchb',
    name: 'BlacklistCar',
    component: () => import(/* webpackChunkName: "blacklist" */ '../pages/blacklist/blacklistCar'),
    meta: {title: '黑名单缴费', requiresAuth: false, isScan: false, client: 'user'}
  },
  {
    path: '/arrears',
    name: 'ArrearsPay',
    component: () => import(/* webpackChunkName: "blacklist" */ '../pages/blacklist/arrearsPay'),
    meta: {title: '黑名单', requiresAuth: false, isScan: false, client: 'user'}
  },

  /************************   商户相关路由  *****************************/
  {
    path: '/merchant/login',
    name: 'MerchantLogin',
    component: () => import(/* webpackChunkName: "merchant" */ '../pages/merchant/login'),
    meta: { title: '商家登录', requiresAuth: false, isScan: false, client: 'merchant' }
  },
  {
    path: '/merchant/home',
    name: 'MerchantHome',
    component: () => import(/* webpackChunkName: "merchant" */ '../pages/merchant/home'),
    meta: { title: '商家中心', requiresAuth: true, isScan: false, client: 'merchant' }
  },
  {
    path: '/merchant/update-pwd',
    name: 'MerchantUpdatePwd',
    component: () => import(/* webpackChunkName: "merchant" */ '../pages/merchant/updatepwd'),
    meta: { title: '修改密码', requiresAuth: true, isScan: false, client: 'merchant' }
  },
  {
    path: '/merchant/car-search',
    name: 'MerchantCarSearch',
    component: () => import(/* webpackChunkName: "merchant" */ '../pages/merchant/car-search'),
    meta: { title: '车牌查询', requiresAuth: true, isScan: false, client: 'merchant' }
  },
  {
    path: '/merchant/carlist',
    name: 'MerchantCarlist',
    component: () => import(/* webpackChunkName: "merchant" */ '../pages/merchant/carlist'),
    meta: { title: '车牌赠送列表', requiresAuth: true, isScan: false, client: 'merchant' }
  },
  {
    path: '/merchant/present',
    name: 'MerchantPresent',
    component: () => import(/* webpackChunkName: "merchant" */ '../pages/merchant/car-present'),
    meta: { title: '优惠赠送', requiresAuth: true, isScan: false, client: 'merchant' }
  },
  {
    path: '/merchant/present-records',
    name: 'MerchantPresentRecords',
    component: () => import(/* webpackChunkName: "merchant" */ '../pages/merchant/present-records'),
    meta: { title: '赠送记录', requiresAuth: true, isScan: false, client: 'merchant' }
  },
  {
    path: '/merchant/recharge-records',
    name: 'MerchantRechargeRecords',
    component: () => import(/* webpackChunkName: "merchant" */ '../pages/merchant/recharge-records'),
    meta: { title: '充值记录', requiresAuth: true, isScan: false, client: 'merchant' }
  },
  {
    path: '/merchant/recharge',
    name: 'MerchantRecharge',
    component: () => import(/* webpackChunkName: "merchant" */ '../pages/merchant/recharge'),
    meta: { title: '商户充值', requiresAuth: true, isScan: false, client: 'merchant' }
  },
  {
    path: '/merchant/receive-coupon',
    name: 'ReceiveCoupon',
    component: () => import(/* webpackChunkName: "merchant" */ '../pages/merchant/receive-coupon'),
    meta: { title: '优惠券领取', requiresAuth: true, isScan: false, client: 'merchant' }
  },
  {
    path: '/merchant/qrcode-static',
    name: 'QrcodeStatic',
    component: () => import(/* webpackChunkName: "merchant" */ '../pages/merchant/qrcode-static'),
    meta: { title: '固定二维码', requiresAuth: true, isScan: false, client: 'merchant' }
  },
  {
    path: '/merchant/qrcode-dynamic',
    name: 'QrcodeDynamic',
    component: () => import(/* webpackChunkName: "merchant" */ '../pages/merchant/qrcode-dynamic'),
    meta: { title: '动态二维码', requiresAuth: true, isScan: false, client: 'merchant' }
  },
  {
    path: '/merchant/qrcode-paper',
    name: 'QrcodePaper',
    component: () => import(/* webpackChunkName: "merchant" */ '../pages/merchant/qrcode-paper'),
    meta: { title: '纸质二维码', requiresAuth: true, isScan: false, client: 'merchant' }
  },
  {
    path: '/merchant/qrcode-paper-add',
    name: 'QrcodePaperAdd',
    component: () => import(/* webpackChunkName: "merchant" */ '../pages/merchant/qrcode-paper-add'),
    meta: { title: '纸质二维码添加', requiresAuth: true, isScan: false, client: 'merchant' }
  },
  {
    path: '/merchant/qrcode-share',
    name: 'QrcodeShare',
    component: () => import(/* webpackChunkName: "merchant" */ '../pages/merchant/qrcode-share'),
    meta: { title: '停车优惠券', requiresAuth: false, isScan: false, client: 'merchant' }
  },
  {
    path: '/home-desc',
    name: 'QrcodeHomeDesc',
    component: () => import(/* webpackChunkName: "merchant" */ '../pages/homedesc'),
    meta: { title: '授权', requiresAuth: false, isScan: false, client: 'user' }
  },

  {
    path: '*',
    name: 'Error',
    component: () => import('../pages/error'),
    meta: { title: '粗错了~', requiresAuth: false, isScan: false, client: 'user' }
  },
]

const router = new VueRouter({
  mode:'history',  //改为history模式
  routes
})

export default router
