import router from '@/router'
import { getUserInfoByOpenid, apiScanInfo, getAlipUserId } from "@/utils/api";
import { getQueryString, getRequest, JudgeUA, urlencode } from "@/utils/common";
import { Notify } from "vant";
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'// progress bar style

NProgress.configure({ showSpinner: false })// NProgress Configuration

router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  document.title = to.meta.title

  const ua = JudgeUA()

  let client = to.meta.client

  switch (client) {
    case 'user':
      const scanAuthInfo = localStorage.getItem('scanAuthInfo') || null
      const userInfo = localStorage.getItem('userInfo') || null
      const openid = localStorage.getItem('openid') || null

      // 判断是否微信，是否需要授权
      switch (ua) {
        case 'wechat':
          if(to.meta.isScan){ // 扫码页面
            if(to.query.isDesc){
              if(scanAuthInfo){
                if(to.path === '/payorder' && to.query.openid){
                  next()
                }else if(to.path === '/payorder' && to.query.code){
                  window.location.href = '/api/u/wx/wxAutomaticAauthInvoke?dataType=parkingNo&data='+to.query.p.split("-")[1]+'&redirectUri='+encodeURIComponent(window.location.origin+to.fullPath+'&isDesc=true')+"&code="+getQueryString('code')
                }else if(to.path === '/payorder'){
                  window.location.href = '/api/u/wx/wxAutomatic?dataType=parkingNo&data='+to.query.p.split("-")[1]+'&redirectUri='+ encodeURIComponent(window.location.origin + to.fullPath+'&isDesc=true')
                }else{
                  next()
                }
              }else {
                if (getQueryString('code')) {
                  let param = {
                    configValue: 1,
                    u: window.location.origin + to.fullPath,
                    code: getQueryString('code')
                  }
                  apiScanInfo(param)
                      .then(result => {
                        if (result.status == "200") {
                          let scanAuthInfo = {
                            openid: result.data.openid,
                            unionid: result.data.unionid,
                            headimgurl: result.data.headimgurl,
                            nickname: result.data.nickname,
                            registrationId: result.data.registrationId,
                          }
                          localStorage.setItem("scanAuthInfo", JSON.stringify(scanAuthInfo))
                          next()
                        } else {
                          Notify({type: "danger", message: "获取用户信息失败，请重新扫码！"})
                          return;
                        }
                      })
                      .catch(err => {
                        Notify({type: "danger", message: err})
                        window.console.log("err：" + err)
                      })
                } else {
                  window.location.href = '/api/u/wx/authV3?configValue=1&redirectUri=' + encodeURIComponent(window.location.origin + to.fullPath+'&isDesc=true')
                }
              }
            }else{
              let _url= window.location.origin+to.fullPath+'&title='+to.meta.title+'&requiresAuth='+to.meta.requiresAuth+'&isScan='+to.meta.isScan+
                  '&client='+to.meta.client
              window.location.href =  window.location.origin + "/home-desc?skipUrl="+encodeURIComponent(_url);
            }


          }else{ // 公众号内页面
            if(userInfo){
              let phone = localStorage.getItem('phone') || null
              if((phone == null && to.path === '/bindphone') || (phone == null && to.path === '/agreement')){
                next()
              } else if(phone == null){
                next('/bindphone')
              }else if(to.path === '/paymentinfo'){
                if(to.query.openid){
                  next()
                }else if(to.query.code){
                  window.location.href = '/api/u/wx/wxAutomaticAauthInvoke?dataType=parkingNo&data='+to.query.orderNo+'&redirectUri='+window.location.origin+to.fullPath+"&code="+getQueryString('code')
                }else{
                  window.location.href = '/api/u/wx/wxAutomatic?dataType=parkingNo&data='+to.query.orderNo+'&redirectUri='+window.location.origin + to.fullPath
                }
              }else {
                next()
              }
            }else{
              if(to.meta.requiresAuth){
                if (getQueryString('openid')){
                  let param = {
                    configValue: 1,
                    openid: getQueryString('openid')
                  }
                  getUserInfoByOpenid(param)
                  .then(result => {
                    localStorage.setItem("userInfo", JSON.stringify(result.data.userInfo))
                    localStorage.setItem("openid", result.data.userInfo.openid)
                    localStorage.setItem("unionid", result.data.userInfo.unionid)
                    localStorage.setItem("phone", result.data.userInfo.phoneMobile)
                    localStorage.setItem("registrationId", result.data.userInfo.registrationId)
                    if (result.status == "404" && to.path === '/bindphone'){
                      next()
                    } else if(result.status == "404"){
                      next('/bindphone') 
                    }else{
                      next()
                    }
                  })
                  .catch(err => {
                    window.console.log("err：" + err)
                  })
                } else {
                  if (getQueryString('code')){
                    window.location.href = '/api/u/wx/wxAutomaticAauthInvoke?dataType=userInfo&data=1&redirectUri='+window.location.origin+to.path+"&code="+getQueryString('code')
                  } else {
                    window.location.href = '/api/u/wx/wxAutomatic?dataType=userInfo&data=1&redirectUri='+window.location.origin + to.path
                  }
                }
              }else{
                next() 
              }
            }
          }
          
          break
        case 'alipay':
          if(to.path === '/payorder'){
            if (!to.query.auth_code && !to.query.userId) {
              let redirecturi = window.location.origin + "/payorder" + urlencode(to.query)
              /**
               * 正式appid :2021001163657169
               * 测试appid:2021001197609841
               */
              // window.location.href = "https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021001197609841&scope=auth_base&redirect_uri=" + encodeURIComponent(redirecturi)
              window.location.href = "https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021001163657169&scope=auth_base&redirect_uri=" + encodeURIComponent(redirecturi)
            } else if (!to.query.userId) {
              let that = this
              let par = {
                  code: to.query.auth_code,
                  parkingNo: to.query.p.split("-")[1]
              }
              getAlipUserId(par) 
                .then(result => {
                  if (result.status == "200") {
                    let urlPar = {
                      token: to.query.token,
                      userId: result.data.userId,
                      p: to.query.p
                    }
  
                    let redirecturi = window.location.origin + "/payorder" + urlencode(urlPar)
  
                    // alert(redirecturi)
                    next({ path: '/payorder', query: urlPar })
                    // window.location.href = redirecturi;
                  }
                })
                .catch(err => {
                  window.console.log(err)
                });
              // next();
            } else {
              next()
            }
          }else{
            next()
          }

          break
        default:
          if(to.meta.isScan){
            next()
          }else if(to.path === '/inside-fixedcode'){
            next()
          }else{
            next('/inside-fixedcode')
          }
          break
      }
      
      break;
    case 'merchant':
      const merchantInfo = localStorage.getItem('merchantInfo') || null
      const merchantLoginName = localStorage.getItem('merchantLoginName') || null

      if(to.meta.requiresAuth){
        if(merchantInfo){
          if(to.path === '/merchant/recharge' && to.query.openid){
            next()
          }else if(to.path === '/merchant/recharge' && to.query.code){ 
            window.location.href = '/api/u/wx/wxAutomaticAauthInvoke?dataType=merchants&data='+JSON.parse(merchantInfo).unitId+'&redirectUri='+window.location.origin+to.path+"&code="+getQueryString('code')
          }else if(to.path === '/merchant/recharge'){
            window.location.href = '/api/u/wx/wxAutomatic?dataType=merchants&data='+JSON.parse(merchantInfo).unitId+'&redirectUri='+window.location.origin + to.path
          }else{
            next()
          }
        }else{
          if(to.path === '/merchant/login'){
            next()
          }else{
            next('/merchant/login')
          }
        }
      }else{
        next()
      }

      break;
    default:
      break;
  }


})

router.afterEach((to, from) => {
  document.title = to.meta.title
  NProgress.done() // finish progress bar
})
