import axios from 'axios'
import qs from 'qs'


// 创建axios实例，并自定义配置
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'; //配置请求头
const service = axios.create({
  // baseURL: process.env.BASE_API, // api 的 base_url
  // baseURL: 'http://yueyang.com', // api 的 base_url
  // baseURL: 'http://localhost:80', // api 的 base_url
  timeout: 10000, // request timeout
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    if (config.method === 'post') {
      config.data.token = config.data.token? config.data.token : 'token'
      config.data = qs.stringify(config.data)
    }
    console.log('【' + config.url + '====请求数据】')
    console.log(config.data)
    return config
  },
  error => {
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.status && res.message) {
        console.log('【响应数据】')
        console.log(response.data)
        return response.data
    } else {
      return Promise.reject(new Error('error'))
    }
  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(new Error('error'))
  }
)

//返回一个Promise(发送post请求)
export function fetchPost(url, params) {
  return new Promise((resolve, reject) => {
    service.post(url, params)
    .then(response => {
        resolve(response);
    }, err => {
        reject(err);
    })
    .catch((error) => {
        reject(error)
    })
  })
}
//返回一个Promise(发送get请求)
export function fetchGet(url, param) {
  return new Promise((resolve, reject) => {
    service.get(url, {
        params: param
    })
    .then(response => {
        resolve(response)
    }, err => {
        reject(err)
    })
    .catch((error) => {
        reject(error)
    })
  })
}

export default { service, fetchPost, fetchGet }
