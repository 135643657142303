import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { Row, Col, Notify, Dialog, Form, Field, Button, Toast, Tab, Tabs, Collapse, CollapseItem, DatetimePicker, Sticky, List, Icon, Popup, PullRefresh, RadioGroup, Radio, Stepper, Search, Overlay, CountDown} from 'vant'
import 'vant/lib/index.css';
import 'vant/lib/icon/local.css';//引用字体css
Vue.use(Row).use(Col).use(Notify).use(Dialog).use(Form).use(Field).use(Button).use(Toast).use(Tab).use(Tabs).use(Collapse).use(CollapseItem).use(DatetimePicker).use(Sticky).use(List).use(Icon).use(Popup).use(PullRefresh).use(RadioGroup).use(Radio).use(Stepper).use(Search).use(Overlay).use(CountDown)
import { Image as VanImage } from 'vant'
Vue.use(VanImage)

import './assets/common.scss'

import axios from './utils/axios'

import 'amfe-flexible/index.js'
import './utils/permission'

Vue.prototype.$axios = axios

Vue.config.productionTip = false

import FastClick from 'fastclick'
//初始化FastClick实例。在页面的DOM文档加载完成后
FastClick.attach(document.body)

new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')
