import {
    fetchGet,
    fetchPost
} from './axios'

/**
 * 通过openid获取用户信息
 */
export const getUserInfoByOpenid = param => fetchGet("/api/u/wx/wxOauthInvokeUserInfo", param);

/**
 * 绑定手机号获取短信验证码
 */
export const SendSms = param => fetchPost("/api/sct/sms/SendSms", param);

/**
 * 绑定手机号
 */
export const updateUserPhone = param => fetchPost("/api/u/wx/updateUserPhoneV2", param);

/**
 * 获取用户绑定车牌列表接口
 */
export const apiCarlist = param => fetchPost('/api/u/listBindingCar', param);

/**
 * 删除/解绑车牌接口
 */
export const apiDelCar = param => fetchPost('/api/u/deleteBindingCar', param);

/**
 * 用户绑定车牌接口
 */
export const apiBindCar = param => fetchPost('/api/u/saveBindingCar', param);

/**
 * 根据用户UNIONID获取临停车牌号
 */
export const apiGetTemporaryCar = param => fetchPost("/api/pking/getNonePlateInfo", param);

/**
 * 查询场内订单编码
 */
export const apiInsideOrder = param => fetchPost("/api/pking/listInsideCarByCarLicense", param);

/**
 * 订单编码查询订单详情
 */
export const apiOrderDetail = param => fetchPost("/api/pking/getPayParkingRecord", param);

/**
 * 查询停车场支持的支付方式
 */
export const apiGetPayList = param => fetchPost("/api/xp/getPayList", param);

/**
 * 统一下单请求
 */
export const apiUnifiedOrder = param => fetchPost("/api/xp/xPay", param);

/**
 * 通过停车场Id获取计费规则特殊显示方式（特殊停车场，如：大兴机场）
 */
export const apiSpecialChargeRule = param => fetchPost("/api/pklot/chargeDescription", param);

/**
 * 通过停车场Id获取计费规则
 */
export const apiChargeRule = param => fetchPost("/api/pklot/listChargeRules", param);

/**
 * 查询免费离场时间
 */
export const apiPaidFreeTime = param => fetchPost('/api/pking/getPaidFreeTimeById', param);

/**
 * 扫除口固定码，根据出口道闸编码查询出口订单编码
 */
export const apiExitOrder = param => fetchPost("/api/pking/getDrivewayExitCar", param);

/**
 * unionId查询无牌车订单
 */
export const apiUnlicensedCarOrder = param => fetchPost("/api/pking/getNonePlateRecord", param);

/**
 * 支付结果查询
 */
export const apiPayResult = param => fetchPost("/api/xp/getPayStatus", param);

/**
 * 微信直联支付时，通过code获取openid
 */
export const apiOpenid = param => fetchGet("/api/u/wx/oauthInvokeV2", param);

/**
 * 字典表查询
 */
export const apiDict = param => fetchPost("/api/sys/listDictByType", param);

/**
 * 查询我的订单列表
 */
// export const apiOrderLists = param => fetchPost("/api/u/listFinancePayRecordV2", param);
export const apiOrderLists = param => fetchPost("/api/u/listFinancePayRecord", param);

/**
 * 根据订单号查询订单详情
 */
export const apiOrderInfo = param => fetchPost("/api/u/listFinancePayOrderDetails", param);

/**
 * 订单问题反馈
 */
export const questionFeedback = param => fetchPost("/api/u/addcscOrderQuestions", param);

/**
 * 月租支付调起
 */
export const xpxPay = param => fetchPost("/api/xp/xPay", param);

/**
 * 获取web确认支付界面
 */
export const getMonthlyPayInfo = param => fetchPost("/api/pklot/web/getMonthlyPayInfo", param);

/**
 * 根据微信code 获取openid
 */
export const oauthInvokeV2 = param => fetchGet("/api/u/wx/oauthInvokeV2", param);

/**
 * 获取车辆类型（判断是否为黑名车、是否存在在场订单等）
 */
 export const getCarType = param => fetchPost("/api/pklot/blacklist/getCarType", param);

 /**
 * 获取黑名单车辆订单总金额
 */
export const blacklistPay = param => fetchPost("/api/xp/xPay", param);

/**
 * 根据停车场code获取黑名单车辆订单
 */
 export const getOrderParkCode = param => fetchPost("/api/pklot/blacklist/getCarOrderByParkinglotCode", param);

 /**
  * 根据停车场code获取黑名单车辆订单总金额
  */
 export const getOrderSumParkCode = param => fetchPost("/api/pklot/blacklist/getCarOrderSumByParkinglotCode", param);

 /**
 * 获取黑名单车辆订单
 */
export const getCarOrder = param => fetchPost("/api/pklot/blacklist/getCarOrder", param);

/**
 * 获取黑名单车辆订单总金额
 */
 export const getCarOrderSum = param => fetchPost("/api/pklot/blacklist/getCarOrderSum", param);

 /**
 * 获取黑名单动态码出口获取车辆类型
 */

export const getCarTypeByParkNo = param => fetchPost("/api/pklot/blacklist/getCarTypeByParkingNo", param);

 /**
  * 根据停车场code获取车辆类型
  */
 export const getCarTypeByParkingCode = param => fetchPost("/api/pklot/blacklist/getCarTypeByParkingCode", param);
 
 /**
  * 根据优惠券id查询停车场编码
  */
 export const getParkinglotCodeByCouponid = param => fetchPost("/api/mcs/qrcodeGetParkingCode", param);


/************************商户相关接口*****************************/


/**
 * 账号密码登录
 */
export const webLogin = param => fetchPost("/api/sct/webLogin", param);

/**
 * 手机号登录
 */
export const webLoginV2 = param => fetchPost("/api/sct/webLoginV2", param);

/**
 * 商户修改密码
 */
export const updateAccountPassword = param => fetchPost("/api/sys/updateAccountPassword", param);

/**
 * 商户查询可赠送车辆列表
 */
export const listCarRecord = param => fetchPost("/api/pking/listCarRecord", param);

/**
 * 获取商户支持的赠送方式及商户余额及规则
 */
export const listBalance = param => fetchPost("/api/mcs/getBalance", param);

/**
 * 获取已赠送额度
 */
export const totalPresent = param => fetchPost("/api/pking/getTotalPresent", param);

/**
 * 获取已赠送额度
 */
export const getDetailedRecord = param => fetchPost("/api/pking/getDetailedRecord", param);

/**
 * 获取计费信息
 */
export const parkingInfo = param => fetchPost("/api/pking/ext/getParkingInfo", param);

/**
 * 商家赠送
 */
export const merchantPresent = param => fetchPost("/api/mcs/savePresent", param);

/**
 * 商家赠送规则
 */
export const merchantsRule = param => fetchPost("/api/mcs/listMerchantsRule", param);

/**
 * 查询二维码列表（固定、动态、纸质）
 */
export const qrcodeList = param => fetchPost("/api/mcs/listDynamicQRCode", param);

/**
 * 查询获取动态二维码key
 */
export const dynamicQRCodeUseKey = param => fetchPost("/api/mcs/setDynamicQRCodeUseKey", param);

/**
 * 添加纸质券动态
 */
export const addPaperQRCode = param => fetchPost("/api/mcs/oneGenerateQRCode", param);

/**
 * 查询商家资产（已赠送、剩余）
 */
export const merchantProperty = param => fetchPost("/api/mcs/countWeChatPresentTotal", param);

/**
 * 商家充值
 */
export const accountInfo = param => fetchPost("/api/mcs/getAccountIdByUnitId", param);

/**
 * 充值记录
 */
export const rechargeRecords = param => fetchPost("/api/mcs/listWeChatRecharge", param);

/**
 * 赠送记录
 */
export const presentRecords = param => fetchPost("/api/mcs/listWeChatPresent", param);

/**
 * 微信手动授权后，获取用户信息
 */
export const apiScanInfo = param => fetchPost("/api/u/wx/oauthInvokeV3", param);

/**
 * 获取微信签名信息
 */
export const apiSignPackage = param => fetchPost("/api/u/wx/wxShare", param);

/**
 * 支付宝获取用户id
 */
 export const getAlipUserId = param => fetchPost("/api/xp/ali/oauthInvokeV2", param);











/**
 * 登录/注册接口
 */
export const apiRegisterLogin = param => fetchPost("/api/u/wxRegisterLogin", param);

/**
 * 退出登录
 */
export const apiLogout = param => fetchPost("/api/u/logoutToUntyingUnionId", param);

/**
 * 获取验证码
 */
export const apiVerificationCode = param => fetchPost("/api/u/login/sms", param);

/**
 * 计费时段查询
 */
export const apiChargeTime = param => fetchPost('/api/pking/getParkingRecordCalculate', param);


/**
 * 用户临停订单查询
 */
export const apiGetOrders = param => fetchPost('/api/u/tempOrder', param);

/**
 * 用户临停订单详情查询
 */
export const apiPayOrderDetail = param => fetchPost('/api/u/tempOrderDetail', param);











/**
 * 通过订单编码查询停车场ID
 */
export const apiParkingLotId = param => fetchPost("/api/pking/getParkingLotIdForDynamic", param);

/**
 * PDA通过订单编码查询停车场ID
 */
export const apiPDAParkingLotId = param => fetchPost("/api/pking/getParkingLotIdByRepayNo", param);





/**
 * PDA订单编码查询订单详情
 */
export const apiPDAOrderDetail = param => fetchPost("/api/pking/getPayParkingRecords", param);



/**
 * 无牌车扫码入场
 */
export const apiUnlicensedCarScanIn = param => fetchPost("/api/pking/nonePlateEntrance", param);

/**
 * 获取openId，重定向
 */
export const apiGetOpenid = param => fetchPost("/api/xp/getRedirectUrlForOpenId", param);


/**
 * 查询停车场是否支持索取电子发票
 */
export const apieInvoice = param => fetchPost("/api/pklot/isSupportInvoice", param);

/**
 * 获取优惠券
 */
export const apiGetQrcodeCoupon = param => fetchPost("/api/mcs/useUnitQrCode", param);



/**
 * 领取动态二维码（即有Key）时，先判断该二维码是否过期
 */
export const apiIsOverdue = param => fetchPost("/api/mcs/ckDynamicQRCodeUseKey", param);

/**
 * 扫码领券，获取车辆订单信息展示
 */
export const apiGetCarinfo = param => fetchPost("api/pking/getParkingInfoByQRCode", param);

/**********************************微信找零api**************************************/

/**
 * 根据id查询找零金额
 */
export const apiGetChangeAmount = param => fetchGet("/api/fce/getNoChangeDetail", param);

/**
 * 获取用户openid
 */
export const apiGetChangeOpenid = param => fetchGet("/api/fce/preChange", param);

/**
 * 找零
 */
export const apiTakeChange = param => fetchGet("/api/fce/hWechatChange", param);

/**********************************机场APP**************************************/
/**
 * 根据停车编码查询场内缴费后免费离场时间
 */
export const apiGetPaidFreeTime = param => fetchPost("/api/ext/getFreeTime", param);

/**********************************开发票**************************************/
/**
 * 新增抬头
 */
export const saveInvoiceTitle = param => fetchPost("/api/fce/saveInvoiceTitle", param);

/**
 * 所有发票抬头
 */
export const listTitle = param => fetchPost("/api/fce/listInvoiceTitle", param);

/**
 * 最新创建的一条默认发票抬头
 */
export const defaultTitle = param => fetchPost("/api/fce/getLastDefaultInvoiceTitle", param);

/**
 * 开票申请
 */
export const applyForInvoice = param => fetchPost("/api/fce/applyForInvoiceV2", param);

/**
 * 开票申请
 */
export const getLastDefaultInvoiceTitle = param => fetchPost("/api/fce/getLastDefaultInvoiceTitle", param);

/**
 * 开票查询列表 公众号
 */
export const listIncomeOfficialAccount = param => fetchPost("/api/fce/listParkIncomeOfficialAccount", param);




/**
 * 批量申请开票 
 */
export const applyInvoiceOfficialAccount = param => fetchPost("/api/fce/applyInvoiceOfficialAccount", param);

